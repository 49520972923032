import { useState } from 'react'
import '../App.css'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';


export const NavBar = () => {
    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="static" className="NavBar">
        <Container maxWidth="xl">
            <Toolbar disableGutters>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', justifyItems: 'flex-end', flexGrow: 1}}>

                <Link to="/" className='noHref noSelect'>
                    { /* LOGO Here */}
                    <img src={process.env.PUBLIC_URL + "/images/nsg-logo2.webp"} alt="Next Solutions Group"  className="logoDynamic" />
                </Link>

                <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'flex', md: 'none' }, justifyContent: 'flex-end'}} >
                    <IconButton
                            style={{border: '1px solid #fff3'}}
                            size="large"
                            aria-label=""
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                            >
                            <MenuIcon />
                            </IconButton>
                        <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >

                    { /* no dynamic for translation */ }
                    <Link to={'/'} style={{textDecoration: 'none', color: '#000'}} className="noSelect" key={'/'} onClick={handleCloseNavMenu}>
                        <MenuItem style={{display: 'flex', justifyContent: 'center', minWidth: '150px'}}  className="menuItem">
                        <div style={{display: 'flex', justifyContent: 'center'}}>Home</div>
                        </MenuItem>
                    </Link>
                    <Link to={'/who'} style={{textDecoration: 'none', color: '#000'}} className="noSelect" key={'/who'} onClick={handleCloseNavMenu}>
                        <MenuItem style={{display: 'flex', justifyContent: 'center', minWidth: '150px'}}  className="menuItem">
                        <div style={{display: 'flex', justifyContent: 'center'}}>Our Team</div>
                        </MenuItem>
                    </Link>

                    {/**
                    <Link to={'/whoadvisors'} style={{textDecoration: 'none', color: '#000'}} className="noSelect" key={'/whoadvisors'} onClick={handleCloseNavMenu}>
                        <MenuItem style={{display: 'flex', justifyContent: 'center', minWidth: '150px'}}  className="menuItem">
                            <div style={{display: 'flex', justifyContent: 'center'}}>Our Advisors</div>
                        </MenuItem>
                    </Link>

                    <Link to={'/what'} style={{textDecoration: 'none', color: '#000'}} className="noSelect" key={'/what'} onClick={handleCloseNavMenu}>
                        <MenuItem style={{display: 'flex', justifyContent: 'center', minWidth: '150px'}} className="menuItem">
                        <div style={{display: 'flex', justifyContent: 'center'}}>What We Do</div>
                        </MenuItem>
                    </Link>

                     */}
                    <Link to={'/careers'} style={{textDecoration: 'none', color: '#000'}} className="noSelect" key={'/careers'} onClick={handleCloseNavMenu}>
                        <MenuItem style={{display: 'flex', justifyContent: 'center', minWidth: '150px'}}  className="menuItem">
                        <div style={{display: 'flex', justifyContent: 'center'}}>Careers</div>
                        </MenuItem>
                    </Link>
                    
                    <Link to={'/thought-leadership'} style={{textDecoration: 'none', color: '#000'}} className="noSelect" key={'/thought-leadership'} onClick={handleCloseNavMenu}>
                        <MenuItem style={{display: 'flex', justifyContent: 'center', minWidth: '150px'}}  className="menuItem">
                        <div style={{display: 'flex', justifyContent: 'center'}}>Our View</div>
                        </MenuItem>
                    </Link>
                    
                    <Link to={'/contact'} style={{textDecoration: 'none', color: '#000'}} className="noSelect" key={'/contact'} onClick={handleCloseNavMenu}>
                        <MenuItem style={{display: 'flex', justifyContent: 'center', minWidth: '150px'}}  className="menuItem">
                        <div style={{display: 'flex', justifyContent: 'center'}}>Contact</div>
                        </MenuItem>
                    </Link>

                
                    </Menu>
                </Box>


                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'right' }}>

                { /* no dynamic for translation */ }
                <Link to={'/'} style={{textDecoration: 'none'}} key={'/home'} onClick={handleCloseNavMenu}>
                    <Button
                        sx={{ my: 2, color: 'white', display: 'block', paddingLeft: '20px', borderBottom: '1px solid transparent', paddingRight: '20px', ':hover': {
                            // theme.palette.primary.main
                            color: '#fffc', backgroundColor: 'transparent !important', borderBottom: '1px solid #f57d05'
                        }}}  className="PageButton"
                    >Home</Button>
                </Link>
                

                { /* Add popup over here 
                <div style={{position: 'relative'}} onMouseLeave={handleWhoClose} >
                    <Link to={'/who'} style={{textDecoration: 'none', position: 'relative'}} key={'who'} onClick={handleCloseNavMenu}
                        onMouseOver={handleWhoOpen}
                        >
                        <Button
                            sx={{ my: 2, color: 'white', display: 'block', paddingLeft: '20px', borderBottom: '1px solid transparent', paddingRight: '20px', ':hover': {
                                // theme.palette.primary.main
                                color: '#fffc', backgroundColor: 'transparent !important', borderBottom: '1px solid #f57d05'
                            }}}  className="PageButton"
                        >Who We Are</Button>
                    </Link>
                    
                
                    <div style={{
                            backgroundColor: '#12232e', 
                            display: 'none', 
                            position: 'absolute', 
                            minWidth: '175px', 
                            border: '1px solid #fff4',
                            marginTop: '-15px',
                            transition: '0.5s',
                            zIndex: 999,
                        }}
                        id="whoSubMenu"
                    >
                        <List style={{ position: 'relative', boxShadow: '0 0 2px 3px #fff3', borderRadius: '5px'}}>
                            <Link to={'/who'} style={{textDecoration: 'none', color: '#FFF'}} key={'/who'} onClick={handleCloseNavMenu}>
                                <ListItemButton style={{
                                    minHeight: '45px',
                                    justifyContent: 'center', 
                                    borderBottom: '1px solid #fff3',
                                    ':hover': {color: '#f00'}
                                }}
                                >Our Team</ListItemButton>
                            </Link>
                            <Link to={'/whoadvisors'} style={{textDecoration: 'none',color: '#FFF'}} key={'/whoadvisors'} onClick={handleCloseNavMenu}>
                                <ListItemButton style={{
                                    minHeight: '45px',
                                    justifyContent: 'center',
                                }}>Our Advisors</ListItemButton>
                            </Link>
                        </List>
                    </div>
                </div>
                */}

                <Link to={'/who'} style={{textDecoration: 'none'}} key={'/who'} onClick={handleCloseNavMenu}>
                    <Button
                        sx={{ my: 2, color: 'white', display: 'block', paddingLeft: '20px', borderBottom: '1px solid transparent', paddingRight: '20px', 
                        ':hover': {
                            color: '#fffc', backgroundColor: 'transparent !important', borderBottom: '1px solid #f57d05'
                        }}}  className="PageButton"
                    >Our Team</Button>
                </Link>
                {/*
                <Link to={'/what'} style={{textDecoration: 'none'}} key={'/what'} onClick={handleCloseNavMenu}>
                    <Button
                        sx={{ my: 2, color: 'white', display: 'block', paddingLeft: '20px', borderBottom: '1px solid transparent', paddingRight: '20px', ':hover': {
                            // theme.palette.primary.main
                            color: '#fffc', backgroundColor: 'transparent !important', borderBottom: '1px solid #f57d05'
                        }}}  className="PageButton"
                    >What We Do</Button>
                </Link>
                */}
                <Link to={'/thought-leadership'} style={{textDecoration: 'none'}} key={'/thought-leadership'} onClick={handleCloseNavMenu}>
                    <Button
                        sx={{ my: 2, color: 'white', display: 'block', paddingLeft: '20px', borderBottom: '1px solid transparent', paddingRight: '20px', ':hover': {
                            // theme.palette.primary.main
                            color: '#fffc', backgroundColor: 'transparent !important', borderBottom: '1px solid #f57d05'
                        }}}  className="PageButton"
                    >Our View</Button>
                </Link>
                <Link to={'/careers'} style={{textDecoration: 'none'}} key={'/careers'} onClick={handleCloseNavMenu}>
                    <Button
                        sx={{ my: 2, color: 'white', display: 'block', paddingLeft: '20px', borderBottom: '1px solid transparent', paddingRight: '20px', ':hover': {
                            // theme.palette.primary.main
                            color: '#fffc', backgroundColor: 'transparent !important', borderBottom: '1px solid #f57d05'
                        }}}  className="PageButton"
                    >Careers</Button>
                </Link>
                <Link to={'/contact'} style={{textDecoration: 'none'}} key={'/contact'} onClick={handleCloseNavMenu}>
                    <Button
                        sx={{ my: 2, color: 'white', display: 'block', paddingLeft: '20px', borderBottom: '1px solid transparent', paddingRight: '20px', ':hover': {
                            // theme.palette.primary.main
                            color: '#fffc', backgroundColor: 'transparent !important', borderBottom: '1px solid #f57d05'
                        }}}  className="PageButton"
                    >Contact</Button>
                </Link>
                </Box>
                </div>
            </Toolbar>
        </Container>
        </AppBar>
    );
};
