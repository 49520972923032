import { useEffect } from 'react'
import { Container } from '@mui/material'
import { Navigate } from 'react-router-dom';

export const What = (props) => {
    useEffect(() => {
        window.scrollTo({left: 0, top: 0, behavior: 'smooth'})
    }, [])

    return (
        <Container style={{overflowY: 'hidden', overflowX: 'hidden', paddingBottom: '120px'}}>
                <Navigate to="/" />
                {/** 
                { WhatHelmet }
                <Fade>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: '3'}}>
                    <h3 style={{color: '#4DA8DA', textTransform: 'uppercase'}}>Next Solutions Group</h3>
                    <h1>What we do</h1>
                </div>
                </Fade>

                <Fade>
                <Grid container style={{justifyContent: 'center', alignItems: 'center', paddingBottom: '50px', zIndex: '2'}}>
                    <Grid item sm={12} md={6} style={{textAlign: 'center'}}>
                        <img src={process.env.PUBLIC_URL + "images/collective.webp"} alt={'collective'}  className={"dynImageWhat"} style={{maxWidth: '440px', maxHeight: '400px'}} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <h3 style={{letterSpacing: '2px'}} className="lightBlueText">About</h3>
                        <h1>Direct Client Experiences</h1>
                        <p style={{fontSize: '1.1em', lineHeight: '1.4em'}}>
                            Our team has a broad range of experience in business, government and journalism. We have supported C-suite executives, top government officials, military leaders and non-profit leaders. We have represented multinational companies in sectors including healthcare, technology, consumer goods and financial services.
                        </p>
                    </Grid>
                </Grid>
                </Fade>

                <Fade>
                <Grid container style={{justifyContent: 'center', alignItems: 'center', paddingBottom: '50px'}} >
                    <Grid item sm={12} md={6} order={{sm: 1, md: 2}} style={{textAlign: 'center'}}>
                        <img src={process.env.PUBLIC_URL + "images/services-image2.webp"} alt={'collective'} style={{maxWidth: '600px'}} className={"imageBase"} />
                    </Grid>

                    <Grid item sm={12} md={6} order={{sm: 2, md: 1}} >
                        <h3 style={{letterSpacing: '2px', textTransform: 'uppercase'}} className="lightBlueText">Experience</h3>
                        <h1>Our Collective Expertise</h1>
                        <ul style={{fontSize: '1.1em', lineHeight: '1.4em'}}>
                            { WhatListCollective.map(item => (
                                <li key={item}>{ item }</li>
                            ))}
                        </ul>
                    </Grid>
                </Grid>
                </Fade>

                <Grid container style={{justifyContent: 'center', alignItems: 'center'}}>
                    <Grid item sm={12} md={6}>
                        <img src={process.env.PUBLIC_URL + "images/services-image.webp"} alt={'collective'} style={{maxWidth: '600px'}} className={"imageBase"} />
                    </Grid>
                </Grid>

                */}
        </Container>
    )
}

/*
const WhatListCollective = [
    "Corporate Communications",
    "Crisis & Issues Management",
    "Intelligence Gathering and Analysis",
    "Government & Public Affairs",
    "Cybersecurity",
    "Executive Engagement",
    "Corporate Partnerships",
    "Executive Media Training",
    "Content Creation",
]
*/